import React from 'react';
import './App.css';
import About from './components/About';
// import Articles from './components/Articles';
// import Research from './components/Research';
import Landing from './components/Landing';

function App() {
    return (
        <div className="App">
            <Landing />
            <About />
            {/* <Research />
            <Articles /> */}
        </div>
    );
}

export default App;
