import React from 'react';
import logo from '../assets/defsig-black.png';

function Landing() {
    return (
        <div className="landing">
            <img src={logo} className="App-logo" alt="logo" height={125} />
            <p><text>Define Signal - Re-imagine information.</text></p>
        </div>
    );
}

export default Landing;
