import React from 'react';

function About() {
    return (
        <div className="about">
            <h2>About</h2>
            <p>Defsig is a pioneer in AI, Blockchain, and Information Theory research. We provide top-notch consulting services to businesses worldwide.</p>
            <p>Solely focused on generalized information exchange. Recognizing that every action/reaction
            is a signal, we are building a platform to enable the exchange of signals between people and systems.
            </p>
        </div>
    );
}

export default About;
